// 1. Include any default functions
@import "../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
$blue : #E76D4D;
$green : #F6C6EA;
$cyan: #C7D36F;
$yellow: #F5DF99;
$red: #9BA3EB;
$dark : #212121;
$white : #ffffff;

@import "../node_modules/bootstrap/scss/variables";
$theme-colors: map-remove($theme-colors, "info", "danger", "success");

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";


@import "../node_modules/bootstrap/scss/grid", "../node_modules/bootstrap/scss/utilities", "../node_modules/bootstrap/scss/reboot", "../node_modules/bootstrap/scss/buttons", "../node_modules/bootstrap/scss/bootstrap.scss";