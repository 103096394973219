@import './getBootstrap5.scss';
body {
  font-size: 15px;
}

@media only screen and (min-width: 767px) {
  .app-left-block {
    // min-height: calc(100vh - 30px);
  }
}

.material-icons-outlined {
  font-size: 16px;
  line-height: 0;
}

.slick-prev:before,
.slick-next:before {
  font-family: initial !important;
}

.slick-track {
  margin-left: 0 !important;
}
.slick-next{
  right: -22px;
}
.nav-tabs {
  border: none;

  .nav-link {
    background: none;
    color: $light;
    border: none;

    &:focus,
    &:hover {
      background: none;
      border: none;
      color: $light;
    }
  }

  .nav-link.active {
    color: $blue;
    border: none;
    background: none;
    border-bottom: 2px solid $blue;
  }
}


.loader {
  position: fixed;
  color: #fff;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .3);
  backdrop-filter: saturate(250%) blur(5px);
}

img.filter-img {
  // filter: hue-rotate(540deg);
}

.slick-disabled {
  cursor: not-allowed !important;
}

.map-container{
  position: absolute;
  width: 100%;
  height: 100%;
}